import './service-teaser.scss';
import { Slider } from '../../components/slider/slider';

class ServiceTeaser {
    constructor (element) {
        this.$serviceTeaser = element;
        this.$sliderItems = this.$serviceTeaser.querySelectorAll('[data-service-teaser="slide"]');
    }

    initialize () {
        this.setSlider();
    }

    setSlider () {
        this.$serviceTeaser = new Slider(this.$serviceTeaser, {
            speed: 500,
            indicators: true,
            slidesPerView: 'auto',
            initAttr: 'data-service-teaser',
            counter: true,
            paginationType: 'fraction',
            fractionText: '/'
        });
    }
}

export { ServiceTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $serviceTeaser = $context.querySelectorAll('[data-service-teaser="root"]');

        for (let i = 0; i < $serviceTeaser.length; i++) {
            const $$serviceTeaser = new ServiceTeaser($serviceTeaser[i]);
            $$serviceTeaser.initialize();
        }
    }
});
